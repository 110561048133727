module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-YMN60C5WBM","cookieName":"gatsby-gdpr-google-analytics"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Venture Friends","short_name":"VF","start_url":"/","background_color":"#FFFFFF","theme_color":"#ff4338","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e9560b4af28341bf7ee3e5dc4adcb072"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
